@import "src/styles/mixins";

.navigation {
  padding: 48px 64px 0;

  @media (max-width: 900px) {
    padding: 24px 24px 0;
  }
}

.navigationBar {
  @include card;
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-content: space-between;
  background-color: #030303;

  @media (max-width: 900px) {
    padding: 24px 12px;
  }

  ul {
    text-align: center;
    list-style-type: none;
    margin: 0;
    padding: 15px;

    li {
      display: inline;
      font-family: "Fira Sans", sans-serif;
      font-size: 16px;

      a {
        color: #bfbfbf;
        text-decoration: none;
        padding: 14px;
        outline: none;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-style: normal;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.logo {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;

  @media (max-width: 320px) {
    font-size: 22px;
  }

  a {
    color: #44d62c;
    text-decoration: none;
  }
}

.mainLogo {
  display: flex;
  align-items: center;

  &__textWrapper {
    border-left: 2px solid #44d62c;
    padding: 0 8px;
  }
}

.logoTitle {
  color: #ededed;
}

.logoSubtitle {
  font-size: 14px;
  //font-weight: normal;
  text-align: start;
}

.nav {
  @media (max-width: 900px) {
    display: none;
  }
}