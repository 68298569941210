.wrapper {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.header {
  text-align: center;
  margin-bottom: 24px;
}

.date {
  color: #626262;
}

.localeSelectWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}

.localeSelectItem {
  cursor: pointer;
  color: #626262;
  margin: 0 16px;

  &__active {
    color: #296433;
  }
}