@import "src/styles/mixins";

.root {
  padding: 0 64px;

  @media (max-width: 900px) {
    padding: 0 24px;
  }
}

.hero {
  @include card;
  display: flex;

  @media (max-width: 1083px) {
    flex-direction: column;
  }

  @media (max-width: 800px) {
    padding: 0;
  }
}

.title {
  font-size: 48px;
  font-weight: 600;
  color: #1d4825;

  @media (max-width: 900px) {
    font-size: 28px;
  }
}

.subtitle {
  font-size: 20px;
  color: #454545;
  line-height: 32px;
}

.column {
  flex-basis: 50%;
}

.textColumn {
  padding-right: 24px;

  @media (max-width: 1083px) {
    margin-bottom: 24px;
  }

  @media (max-width: 800px) {
    padding: 24px;
  }
}

.imageColumn {
  padding-left: 24px;
}

.imageWrapper {
  background-color: #197c2c;
  border-radius: 64px;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  will-change: transform;
  padding-top: 18px;

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.mainImage {
  width: 90%;
  margin-bottom: -5px;
  min-width: 390px;
}

.featureBlock {
  display: flex;
  justify-content: space-between;
  padding: 48px 0 0;
  align-items: stretch;

  @media (max-width: 900px) {
    padding: 24px 0 0;
    flex-direction: column;
  }
}

.featureWrapper {
  @include card;
  flex-basis: 30%;
  margin-right: 24px;
  height: auto;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 900px) {
    margin: 0 0 24px 0;
    padding: 24px;
  }
}

.featureTitle {
  font-weight: bold;
  font-size: 18px;
}

.marketWrapper {
  padding: 70px 0;

  @media (max-width: 502px) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

.marketLink {
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 502px) {
    margin: 0 0 24px;

    &:last-child {
      margin: 0;
    }
  }
}

.marketButton {
  height: 60px;
}
