@import "src/styles/mixins";

.wrapper {
  padding: 48px 64px;

  @media (max-width: 900px) {
    padding: 24px;
  }
}

.root {
  @include card;
}

.links {
  padding: 24px 0;

  a {
    color: #1d4825;
    //color: #f2f2f2;
    text-decoration: none;

    + a {
      margin-left: 16px;
    }
  }
}