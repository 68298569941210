@import "src/styles/mixins";

.welcomeArea {
  min-height: 637px;
  height: auto;
  position: relative;
}

.content {
  padding: 0 64px;

  @media (max-width: 600px) {
    padding: 0 24px;
  }
}

.description {
  @include card;
  background-color: initial;
  max-width: 880px;
  margin: 24px auto;
  padding: 0 48px;
}

.title {
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 1.4px;
  color: #1d4825;
  margin-bottom: 30px;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  left: 0.53px;
  color: #454545;
  margin-bottom: 30px;
}

.appImage {
  width: 100px;
}

.appBlock {
  padding: 48px 0;
}

.appLink {
  text-decoration: none;
}

.appWrapper {
  @include card;
  display: flex;
  max-width: 640px;
  margin: 24px auto;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 24px;
    align-items: center;
  }
}

.appLogoWrapper {
  width: 120px;
  min-width: 120px;
  height: 120px;
  border-radius: 24px;
  background: #2e3033;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appTitle {
  font-size: 32px;
  font-weight: bold;
  color: #1d4825;

  @media (max-width: 600px) {
    font-size: 28px;
  }
}

.appContent {
  padding-left: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  color: #706f6f;

  @media (max-width: 600px) {
    padding: 24px 0 0;
    align-items: center;
    text-align: center;
  }
}