.root {
  max-width: 1024px;
  margin: 0 auto;
  padding: 16px 32px;
}

.promoWrapper {
  margin-bottom: 36px;
}

.promo {
  font-weight: bold;
  font-size: 24px;
  color: #6f706f;
  margin-bottom: 4px;
}

.sfqImage {
  max-width: 1024px;
  width: 100%;
  height: auto;
  margin-bottom: 36px;
}

.description {
  color: #b5b5b5;
  text-align: start;
}

.links {
  margin-top: 36px;

  a {
    color: #bfbfbf;
    text-decoration: none;
    outline: none;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: #bfbfbf;
    }
  }
}

.header {
  color: #bfbfbf;
}